<template>
  <recebimento-dados-nivel-1-agrupado
    ref="nivel_1_cards"
    @Recebimento__SelecionaItemNivel1="selectItem">
  </recebimento-dados-nivel-1-agrupado>
</template>

<script>
import RecebimentoDadosNivel1Agrupado from '@/spa/liquidacao-acao/recebimento/RecebimentoDadosNivel1Agrupado';

export default {
  data() {
    return {
      visao: 'Status',
    };
  },
  components: {
    RecebimentoDadosNivel1Agrupado,
  },
  methods: {
    selectItem(item, router, visaoSelecionada) {
      if (visaoSelecionada === 'Status') {
        this.$router.push({
          name: router,
          params: {
            status: item.status,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else if (visaoSelecionada === 'Cliente') {
        this.$router.push({
          name: router,
          params: {
            idCliente: item.idCliente,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      } else {
        this.$router.push({
          name: router,
          params: {
            idFornecedor: item.idFornecedor,
            objetoAcao: item,
          },
          query: {
            origem: item.titulo,
          },
        });
      }
    },
  },
  beforeMount() {
    this.visao = this.$route.params.visao ? this.$route.params.visao : 'Status';
  },
};
</script>
